/* eslint-disable  */

import classNames from 'classnames/bind';
import React from 'react';
// import Dropdown, { Option } from "react-dropdown";
// import { FormattedMessage, useIntl } from "react-intl";
// import { useNavigate, useLocation } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";

// import ChimeSdkWrapper from "../chime/ChimeSdkWrapper";
// import routes from "../constants/routes.json";
// import getChimeContext from "../context/getChimeContext";
// import getUIStateContext from "../context/getUIStateContext";
// import ClassMode from "../enums/ClassMode";
// import RegionType from "../types/RegionType";
import styles from './CreateOrJoin.css';
// import OptionalFeature from "../enums/OptionalFeature";
// import localStorageKeys from "../constants/localStorageKeys.json";

const cx = classNames.bind(styles);

// const optionalFeatures = [
//   { label: "None", value: OptionalFeature.None },
//   { label: "Enable Simulcast For Chrome", value: OptionalFeature.Simulcast },
// ];

export default function CreateOrJoin() {
  // const chime = useContext(getChimeContext()) as ChimeSdkWrapper;
  // const [state] = useContext(getUIStateContext());
  // const [title, setTitle] = useState('');
  // const [name, setName] = useState("");
  // const [region, setRegion] = useState<RegionType | undefined>(undefined);
  // const [optionalFeature, setOptionalFeature] = useState("");
  // const navigate = useNavigate();
  // const intl = useIntl();
  // const query = new URLSearchParams(useLocation().search);

  // const invitedUrl = localStorage.getItem(localStorageKeys.INVITED_URL);
  // const meetingID = query.get("meetingID") || "";

  // useEffect(() => {
  //   // if (invitedUrl) {
  //   //   let isRecordingUrl =
  //   //     invitedUrl.split("&")[1] == "record=true" ? true : false;
  //   //   const url = new URL(invitedUrl);
  //   //   const urlParams = new URLSearchParams(url.search);
  //   //   const meetingParam = urlParams.get("meetingId");
  //   //   if (meetingParam && !isRecordingUrl) {
  //   //     setTitle(meetingParam);
  //   //   }
  //   //   if (meetingParam && isRecordingUrl) {
  //   //     navigate(
  //   //       `/classroom?title=${encodeURIComponent(
  //   //         meetingParam
  //   //       )}&name=Unknown&region=us-east-1`
  //   //     );
  //   //   }

  //   //   if (meetingParam) {
  //   //   }
  //   // }
  //   if(meetingID){
  //     setTitle(meetingID);
  //   }
  // }, []);

  // useEffect(() => {
  //   setOptionalFeature(optionalFeatures[0].value);
  //   (async () => {
  //     setRegion(await chime?.lookupClosestChimeRegion());
  //   })();
  // }, []);

  return (
    <div className={cx('CreateOrJoin_createOrJoin')}>
      <div className={cx('CreateOrJoin_formWrapper')}>
        <img src="/logo.webp" alt="" />
        {/* <h1 className={cx("CreateOrJoin_title")}> */}
        {/* {state.classMode === ClassMode.Teacher ? (
            <FormattedMessage id="CreateOrJoin.teacherTitle" />
          ) : ( */}
        {/* <FormattedMessage id="CreateOrJoin.studentTitle" /> */}
        {/* )} */}
        {/* </h1> */}
        {/* <form
          className={cx("CreateOrJoin_form")}
          onSubmit={(event) => {
            event.preventDefault();
            if (title && name) {
              navigate(
                `/classroom?meetingID=${encodeURIComponent(title)}&userName=${encodeURIComponent(name)}&mode=${encodeURIComponent("ap")}`
              );
            }
          }}
        >
          <input
            value={title}
            className={cx("CreateOrJoin_titleInput")}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "CreateOrJoin.titlePlaceholder",
            })}
          />
          <input
            className={cx("CreateOrJoin_nameInput")}
            onChange={(event) => {
              setName(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "CreateOrJoin.namePlaceholder",
            })}
          />
          // {state.classMode === ClassMode.Teacher && (
          //   <div className={cx("CreateOrJoin_regionsList")}>
          //     <Dropdown
          //       className={cx("CreateOrJoin_dropdown")}
          //       controlClassName={cx("CreateOrJoin_control")}
          //       placeholderClassName={cx("CreateOrJoin_placeholder")}
          //       menuClassName={cx("CreateOrJoin_menu")}
          //       arrowClassName={cx("CreateOrJoin_arrow")}
          //       value={region}
          //       options={
          //         region ? chime?.supportedChimeRegions : ([] as RegionType[])
          //       }
          //       disabled={!region}
          //       onChange={(selectedRegion: RegionType) => {
          //         setRegion(selectedRegion);
          //       }}
          //       placeholder=""
          //     />
          //   </div>
          // )}

          // <div className={cx("CreateOrJoin_regionsList")}>
          //   <Dropdown
          //     className={cx("CreateOrJoin_dropdown")}
          //     controlClassName={cx("CreateOrJoin_control")}
          //     placeholderClassName={cx("CreateOrJoin_placeholder")}
          //     menuClassName={cx("CreateOrJoin_menu")}
          //     arrowClassName={cx("CreateOrJoin_arrow")}
          //     value={optionalFeature}
          //     options={optionalFeatures}
          //     onChange={(selectedFeature: Option) => {
          //       setOptionalFeature(selectedFeature.value);
          //     }}
          //     placeholder={optionalFeatures[0].label}
          //   />
          // </div> 

          <button className={cx("CreateOrJoin_button")} type="submit">
            <FormattedMessage id="CreateOrJoin.continueButton" />
          </button>
        </form>*/}
        {/* <Link className={cx("CreateOrJoin_mainLink")} to={routes.MAIN}>
          {<FormattedMessage id="CreateOrJoin.back" />}
        </Link> */}
      </div>
    </div>
  );
}
