/* eslint-disable  */

import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DataMessage } from 'amazon-chime-sdk-js';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
// import getUIStateContext from "../context/getUIStateContext";
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import ClassMode from '../enums/ClassMode';
import MessageTopic from '../enums/MessageTopic';
import MeetingStatus from '../enums/MeetingStatus';
// import common from "../constants/common.json";
import routes from '../constants/routes.json';
import {
  useNotificationDispatch,
  Type as NotifType,
} from '../providers/NotificationProvider';

export default function useRemoteControl() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { meetingStatus } = useContext(getMeetingStatusContext());
  const notifDispatch = useNotificationDispatch();
  const intl = useIntl();

  const { globalVar, updateGlobalVar } = useContext(getGlobalVarContext());
  const { classMode } = globalVar;
  // const [state] = useContext(getUIStateContext());
  const navigate = useNavigate();
  const localUserId =
    chime?.meetingSession?.configuration?.credentials?.attendeeId;

  useEffect(() => {
    if (meetingStatus !== MeetingStatus.Succeeded) {
      return;
    }

    const callback = async (message: DataMessage) => {
      if (classMode === ClassMode.Teacher) {
        return;
      }
      const { topic } = message;
      const { focus, targetId } = message.json();

      switch (topic) {
        case MessageTopic.RemoteMuteUnmute:
          if (targetId === localUserId) {
            // chime?.audioVideo?.realtimeSetCanUnmuteLocalAudio(!focus);
            if (focus) {
              chime?.audioVideo?.realtimeMuteLocalAudio();
              notifDispatch({
                type: NotifType.REMOTE_MUTE,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.remoteMute',
                  }),
                },
              });
            } else {
              chime?.audioVideo?.realtimeUnmuteLocalAudio();
              notifDispatch({
                type: NotifType.REMOTE_UNMUTE,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.remoteUnMute',
                  }),
                },
              });
            }
          }
          break;
        case MessageTopic.RemoteVideoOnOff:
          if (targetId === localUserId) {
            try {
              if (!chime?.currentVideoInputDevice) {
                throw new Error('currentVideoInputDevice does not exist');
              }
              await chime?.chooseVideoInputDevice(
                chime?.currentVideoInputDevice
              );
              if (focus) {
                chime?.audioVideo?.startLocalVideoTile();
                updateGlobalVar('localVideo', true);
                notifDispatch({
                  type: NotifType.REMOTE_VIDEO_ENABLED,
                  payload: {
                    message: intl.formatMessage({
                      id: 'Notification.remoteVideoStart',
                    }),
                  },
                });
              } else {
                chime?.audioVideo?.stopLocalVideoTile();
                updateGlobalVar('localVideo', false);
                notifDispatch({
                  type: NotifType.REMOTE_VIDEO_DISABLED,
                  payload: {
                    message: intl.formatMessage({
                      id: 'Notification.remoteVideoStop',
                    }),
                  },
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
          break;

        case MessageTopic.ScreenSharePermit:
          if (targetId === localUserId) {
            updateGlobalVar('screenSharePermit', focus);
            if (focus) {
              notifDispatch({
                type: NotifType.SCREEN_SHARE_PERMIT,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.screenSharePermit',
                  }),
                },
              });
            } else {
              chime?.audioVideo?.stopContentShare();
              notifDispatch({
                type: NotifType.SCREEN_SHARE_PERMIT,
                payload: {
                  message: intl.formatMessage({
                    id: 'Notification.screenShareNotPermit',
                  }),
                },
              });
            }
          }
          break;
        case MessageTopic.EndMeeting:
          if (focus) {
            chime?.leaveRoom(false);
            navigate(
              `${routes.MAIN}?appointmentId=${chime?.appointmentId}&mentorId=${chime?.mentorId}&mentorName=${chime?.mentorName}`
            );
          }
          break;
        default:
          break;
      }
    };

    const remoteMuteUnmuteUpdateCallback = {
      topic: MessageTopic.RemoteMuteUnmute,
      callback,
    };
    const remoteVideoOnOffUpdateCallback = {
      topic: MessageTopic.RemoteVideoOnOff,
      callback,
    };
    const screenSharePermitMessageUpdateCallback = {
      topic: MessageTopic.ScreenSharePermit,
      callback,
    };
    const endMeetingMessageUpdateCallback = {
      topic: MessageTopic.EndMeeting,
      callback,
    };

    chime?.subscribeToMessageUpdate(remoteMuteUnmuteUpdateCallback);
    chime?.subscribeToMessageUpdate(remoteVideoOnOffUpdateCallback);
    chime?.subscribeToMessageUpdate(screenSharePermitMessageUpdateCallback);
    chime?.subscribeToMessageUpdate(endMeetingMessageUpdateCallback);
    return () => {
      chime?.unsubscribeFromMessageUpdate(remoteMuteUnmuteUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(remoteVideoOnOffUpdateCallback);
      chime?.unsubscribeFromMessageUpdate(
        screenSharePermitMessageUpdateCallback
      );
      chime?.unsubscribeFromMessageUpdate(endMeetingMessageUpdateCallback);
    };
  }, [meetingStatus]);
}
