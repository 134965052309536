import AttendanceType from '../types/AttendanceType';
import { client } from './client';

export async function attendanceWebhook(data: AttendanceType): Promise<any> {
  const res = await client({
    url: `mentee/appointment/meeting-details`,
    method: 'put',
    data: JSON.stringify(data),
  });

  console.log('attendance', res.data);
  return res.data;
}
