/* eslint-disable  */

import classNames from 'classnames/bind';
import React from 'react';
import { useIntl } from 'react-intl';

import VideoNameplate from './VideoNameplate';
import styles from './RemoteVideo.css';
import Icons from '../custom/Icons';
import useRoster from '../hooks/useRoster';
import RosterAttendeeType from '../types/RosterAttendeeType';

const cx = classNames.bind(styles);

type Props = {
  enabled: boolean;
  videoElementRef: (instance: HTMLVideoElement | null) => void;
  attendeeId: string | null;
  raisedHand?: boolean;
  activeSpeaker?: boolean;
  view?: string;
};

export default React.memo(function RemoteVideo(props: Props) {
  const intl = useIntl();
  const { enabled, videoElementRef, attendeeId, activeSpeaker, view } = props;
  const roster = useRoster();
  const rosterAttendee: RosterAttendeeType = attendeeId
    ? roster[attendeeId]
    : {};

  return (
    <div
      className={cx('RemoteVideo_remoteVideo', {
        enabled,
        // activeSpeaker,
        activeSpeakerViewMode: view === 'activeSpeaker',
      })}
    >
      {activeSpeaker && (
        <div className={cx('Mui_roster_layout_active_speaker_border')}></div>
      )}
      <video muted ref={videoElementRef} className={cx('RemoteVideo_video')} />
      <VideoNameplate
        name={rosterAttendee?.name}
        muted={rosterAttendee?.muted}
      />

      {rosterAttendee?.raised && (
        <div className={cx('RemoteVideo_raisedHand')}>
          <span
            role="img"
            aria-label={intl.formatMessage({
              id: 'RemoteVideo.raiseHandAriaLabel',
            })}
          >
            <Icons src={'/icons/hand_yellow.png'} alt="hand_yellow" />
          </span>
        </div>
      )}
    </div>
  );
});
