enum MessageTopic {
  PublicChannel = 'group-chat',
  GroupChat = 'group-chat-message',
  RaiseHand = 'raise-hand',
  DismissHand = 'dismiss-hand',
  RemoteMuteUnmute = 'remote-mute-unmute',
  RemoteVideoOnOff = 'remote-video-on-off',
  ScreenSharePermit = 'screen-share-permit',
  EndMeeting = 'end-meeting',
}

export default MessageTopic;
