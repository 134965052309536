/* eslint-disable  */

import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation } from 'react-router-dom';

import getUIStateContext from '../context/getUIStateContext';
import ClassMode from '../enums/ClassMode';
import LoadingSpinner from './LoadingSpinner';
import styles from './Main.css';
import routes from '../constants/routes.json';

const cx = classNames.bind(styles);

export default function Main() {
  const query = new URLSearchParams(useLocation().search);
  const [, dispatch] = useContext(getUIStateContext());
  const navigate = useNavigate();
  const meetingName = query.get('meetingName') || '';
  const meetingID = query.get('meetingID') || '';
  const mentorId = query.get('mentorId') || '';
  const batchId = query.get('batchId') || '';
  const userName = query.get('userName') || '';
  const mode = query.get('mode') || '';
  const menteeId = query.get('menteeId') || '';
  const startTime = query.get('startTime') || `${new Date().getTime() / 1000}`;
  const endTime =
    query.get('endTime') || `${(new Date().getTime() + 1 * 60 * 60) / 1000}`;
  const isRecording = query.get('isRecording') || '';

  useEffect(() => {
    // console.log(meetingID, userName, mode);
    // const mTitle = document.getElementById("title");
    // if(mTitle) mTitle.innerHTML = `Mavencliq - ${meetingName || meetingID}`;

    if (!userName || !mode) {
      navigate(`${routes.CREATE_OR_JOIN}`);
    } else {
      if (mode) {
        dispatch({
          type: 'SET_CLASS_MODE',
          payload: {
            classMode:
              mode === 'mentor' ? ClassMode.Teacher : ClassMode.Student,
          },
        });
      }
      const histroyObject = {
        state: {
          meetingID: meetingID,
          userName: userName,
          mode: mode,
          mentorId: mentorId,
          menteeId: menteeId,
          batchId: batchId,
          meetingName: meetingName,
          startTime: startTime,
          endTime: endTime,
          isRecording: isRecording,
        },
      };

      // console.log('🏁🏁🏁🏁🏁', histroyObject);
      navigate(`/classroom?meetingID=${meetingID}`, histroyObject);
    }
  }, []);

  return (
    <div className={cx('Main_main')}>
      <LoadingSpinner></LoadingSpinner>
    </div>
  );
}
