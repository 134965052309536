export function countDownTimer(
  meetingStartMeeting: number,
  timerTag: HTMLElement
) {
  const countDownDate = meetingStartMeeting * 1000;
  const now = new Date().getTime();

  const distance = countDownDate - now;
  if (distance <= 0) {
    return { time: null, shouldShow: false };
  } else {
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const m1 = ('0' + minutes).slice(-2);
    const s1 = ('0' + seconds).slice(-2);

    const ttl = Math.floor(distance / (1000 * 60));

    if (ttl < 10) {
      timerTag.innerHTML = `${m1}:${s1}` as string;
      return { time: `${m1}:${s1}`, shouldShow: true };
    } else {
      timerTag.innerHTML = `${m1}:${s1}` as string;
      return { time: `${m1}:${s1}`, shouldShow: false };
    }
  }
}
