export default {
  "Main.title": `Create/Join meeting`,
  "Main.joinButton": `Join meeting`,
  "Main.createButton": `Create meeting`,

  "checkeMediaPermmissions.allowMicCamera": `Allow App to use your camera and microphone`,
  "checkeMediaPermmissions.needAccessMicCamera": `App needs access to your camera and microphone so that other
  participants can see and hear you.`,
  "checkeMediaPermmissions.blockedMicCamera": `Camera and microphone are blocked`,
  "checkeMediaPermmissions.requiredMicCamera": `App requires access to your camera and microphone.`,
  "checkeMediaPermmissions.cameraBlockedIcon": `Click the camera blocked icon`,
  "checkeMediaPermmissions.browserAddressBar": `in your browser's address bar.`,
  "checkeMediaPermmissions.cantUseMic": `Can't use your camera or microphone`,
  "checkeMediaPermmissions.browserMightNotHaveAccess": `Your browser might not have access to your camera or microphone. To fix this problem, open`,
  "checkeMediaPermmissions.cantStartCameraMicrophone": `Can't start your camera or microphone`,
  "checkeMediaPermmissions.anotherApplication": `Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your webcam. Please turn off other cameras before proceeding.`,
  "checkeMediaPermmissions.errorDetails": `Error Details`,

  "CreateOrJoin.teacherTitle": `Create or join a meeting`,
  "CreateOrJoin.studentTitle": `Join a meeting`,
  "CreateOrJoin.titlePlaceholder": `Enter Meeting id`,
  "CreateOrJoin.namePlaceholder": `Enter your name`,
  "CreateOrJoin.continueButton": `Continue`,
  "CreateOrJoin.back": `Back`,
  "CreateOrJoin.classRoomDoesNotExist": `Meeting does not exist`,
  "CreateOrJoin.serverError": `Server error`,

  "Classroom.classroom": `Classroom`,
  "Classroom.closeRightMenu": `Close menu`,
  "Classroom.openRightMenu": `Open menu`,
  "Classroom.meetingInfo": `Meeting info`,
  "Classroom.deviceSettings": `Device settings`,
  "Classroom.activeSpeakerView": `Active speaker view`,
  "Classroom.gridView": `Grid view`,
  "Classroom.chat": `Chat`,
  "Classroom.exitFullScreen": `Exit full screen`,
  "Classroom.fullScreen": `Full screen`,
  "Classroom.poorConnection": `Your connection is poor.we recommended you please turn off your video.`,
  "Classroom.videoTileLimitExceeded": `Video tile limit exceeded!`,

  "Copyinfo.copyMeetingId": `Copy`,
  "Copyinfo.coppiedMeetingId": `Coppied`,
  "Copyinfo.meetingId": `Meeting Id:`,
  "Copyinfo.copyMeetingInfo": `Copy Meeting Info`,

  "Error.takeMeHome": `Take me home`,

  "RemoteVideoGroup.noVideo": `No one is sharing video`,

  "DeviceSwitcher.microphone": `Change microphone`,
  "DeviceSwitcher.speaker": `Change speakers`,
  "DeviceSwitcher.camera": `Change video camera`,
  "DeviceSwitcher.videoQuality": `Change video quality`,
  "DeviceSwitcher.screenSharePermit": `Who can share screen?`,
  "DeviceSwitcher.noAudioInputPlaceholder": `No microphone device fouund`,
  "DeviceSwitcher.noAudioOutputPlaceholder": `No speaker device fouund`,
  "DeviceSwitcher.noVideoInputPlaceholder": `No video device found`,
  "DeviceSwitcher.hostOnly": `Host Only`,
  "DeviceSwitcher.allParticipants": `All participants`,

  "Controls.turnOffFocusTooltip": `Turn off focus`,
  "Controls.turnOnFocusTooltip": `Turn on focus`,
  "Controls.unmuteTooltip": `Unmute`,
  "Controls.muteTooltip": `Mute`,
  "Controls.canNotTurnOnMicTooltip": `You can not turn on attendee's mic`,
  "Controls.turnOnVideoTooltip": `Turn on video`,
  "Controls.canNotTurnOnVideoTooltip": `You can not start attendee's video`,
  "Controls.turnOffVideoTooltip": `Turn off video`,
  "Controls.startVideo": `Start video`,
  "Controls.stopVideo": `Stop video`,
  "Controls.shareScreenTooltip": `Share screen`,
  "Controls.shareScreenStopTooltip": `Stop share`,
  "Controls.endClassroomTooltip": `End meeting`,
  "Controls.leaveClassroomTooltip": `Leave meeting`,
  "Controls.micMutedInScreenViewMode": `Mic muted`,
  "Controls.hostEnableScreenShare": `Host has enabled screen sharing`,
  "Controls.hostDisableScreenShare": `Host has disabled screen sharing`,
  "Controls.screenSharePermitDialogMessage": `Host disabled participants screen sharing`,
  "Controls.screenShareAlreadySharedDialogMessage": `Someone shared screen already`,
  "Controls.dialogOk": "Ok",
  "Controls.startRecordingScreenTooltip": `Start recording`,
  "Controls.stopRecordingScreenTooltip": `stop recording`,
  "Controls.Chat": `Chat`,
  "Controls.EndMeeting": `End meeting`,
  "Controls.LeaveMeeting": `Leave meeting`,
  "Controls.menu": `More`,
  "Controls.participants": `Participants`,

  "Chat.all": `All`,

  "ChatInput.inputPlaceholder": `Start typing...`,
  "ChatInput.raiseHandAriaLabel": `Raise hand`,
  "ChatInput.dismissHandAriaLabel": `Dismiss hand`,

  "Roster.raiseHandAriaLabel": `Raised hand by {name}`,
  "Roster.users": `Participants`,
  "Roster.sureRemove": `Are you sure to remove {name} from meeting ?`,
  "Roster.makePresenter": `Make presenter`,
  "Roster.removePresenter": `Remove presenter`,

  "RemoteVideo.raiseHandAriaLabel": `Raised hand`,

  "ThankyouPage.message": "Session Completed",
  "ThankyouPage.successfullCompletion":
    "You have successfully completed your session ",
  "ThankyouPage.backToSession": "Back to My Session",
  "Notification.remoteMute": "You have been muted by a host",
  "Notification.remoteUnMute": "You have been unmuted by a host",
  "Notification.remoteVideoStart": "Your video has been started by a host",
  "Notification.remoteVideoStop": "Your video has been turned off by a host",
  "Notification.screenSharePermit": "Host has enabled screen sharing",
  "Notification.screenShareNotPermit": "Host has disabled screen sharing",
};
