/* eslint-disable  */
import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, Box, Typography } from "@mui/material";
import classNames from "classnames/bind";

import ClassMode from "../enums/ClassMode";
import getUIStateContext from "../context/getUIStateContext";
import styles from "./ThankyouPage.css";
import common from "../constants/common.json";
import LoadingSpinner from "./LoadingSpinner";

const cx = classNames.bind(styles);

export default function ThankyouPage() {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const [state] = useContext(getUIStateContext());
  const mentorId = query.get("mentorId") || "";
  const appointmentId = query.get("appointmentId") || "";
  const mentorName = query.get("mentorName") || "";

  useEffect(() => {
    console.log("-------->MAIN", query, mentorId, appointmentId, mentorName);
    if (
      state.classMode === ClassMode.Student &&
      appointmentId &&
      mentorId &&
      mentorName
    ) {
      window.location.href = `${common.domain}/mentee/review-ratings?appointmentId=${appointmentId}&mentorId=${mentorId}&mentorName=${mentorName}`;
    }
  }, []);
  const redirectToMySession = () => {
    window.location.href = `${common.domain}/mentor/my-sessions`;
  };
  return state.classMode === ClassMode.Student &&
    appointmentId &&
    mentorId &&
    mentorName ? (
    <div className={cx("Main_main")}>
      <LoadingSpinner></LoadingSpinner>
    </div>
  ) : (
    <Box className={cx("container")}>
      <img src="/meeting.svg" alt="Session completed" className={cx("image")} />
      <Typography className={cx("text")}>
        {intl.formatMessage({ id: "ThankyouPage.message" })}
      </Typography>
      <Typography className={cx("you-have-successfull")}>
        {intl.formatMessage({ id: "ThankyouPage.successfullCompletion" })}
      </Typography>
      <Button
        className={cx("button")}
        onClick={redirectToMySession}
        variant="contained"
        color="primary"
      >
        <span className={cx("button-text")}>
          {intl.formatMessage({ id: "ThankyouPage.backToSession" })}
        </span>
      </Button>
      <img src="/mavencliq-logo.svg" alt="Logo" className={cx("logo")} />
    </Box>
  );
}
