/* eslint-disable  */

import { VideoTileState } from 'amazon-chime-sdk-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import getGlobalVarContext from '../context/getGlobalVarContext';
import RemoteVideo from './RemoteVideo';
import useRoster from '../hooks/useRoster';
import RosterAttendeeType from '../types/RosterAttendeeType';
import RosterLayout from './RosterLayout';
import LocalVideo from './LocalVideo';
import { Box } from '@mui/material';
import { countDownTimer } from '../utils/countDownTimer';
import styles from './RosterSliderView.css';
import routes from '../constants/routes.json';
import ClassMode from '../enums/ClassMode';

const MAX_REMOTE_VIDEOS = 16;
const cx = classNames.bind(styles);

type Props = {
  isContentShareEnabled: boolean;
  isScreenShareView: boolean;
  rightDrawerOpen: boolean;
};
export default function RosterSliderView(props: Props) {
  const { isContentShareEnabled, rightDrawerOpen } = props;
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { globalVar } = useContext(getGlobalVarContext());
  const { classMode } = globalVar;
  const [visibleIndices, setVisibleIndices] = useState<{
    [index: string]: { boundAttendeeId: string };
  }>({});
  const roster = useRoster();
  const navigate = useNavigate();
  const videoElements: HTMLVideoElement[] = [];
  const tiles: { [index: number]: number } = {};
  const currentUser = chime?.configuration?.credentials?.attendeeId;
  const [videoAttendees, setVideoAttendees] = useState<Set<unknown>>(new Set());
  const [attendeeIds, setAttendeeIds] = useState<string[]>([]);
  const timerElement = useRef(null);

  useEffect(() => {
    const mt = timerElement.current! as HTMLElement;
    const endTime = chime?.endTime;
    if (mt && endTime) {
      const timerTag = document.createElement('span');
      timerTag.classList.add('Mui_roster_slider_red_color');
      const infoTag = document.createElement('span');
      infoTag.innerHTML = 'Your meeting will end in ';
      mt.appendChild(infoTag);
      mt.appendChild(timerTag);
      setInterval(function () {
        const { time, shouldShow } = countDownTimer(
          endTime as number,
          timerTag
        );
        if (!time && classMode === ClassMode.Teacher) {
          chime?.leaveRoom(true);
          navigate(routes.MAIN);
        }
        if (shouldShow) {
          mt.style.display = 'block';
        } else {
          mt.style.display = 'none';
        }
      }, 1000);
    }
  }, []);

  const memoizedValues = useMemo(() => {
    const activeAttendee = Object.keys(roster);
    const filteredArray = activeAttendee
      .filter((item: string) => item !== currentUser)
      .filter((attendeeId: string) => !!roster[attendeeId].name);

    const newAttendeeIds = [...filteredArray];
    return [newAttendeeIds];
  }, [roster, currentUser]);

  useEffect(() => {
    setAttendeeIds(memoizedValues[0] as string[]);
  }, [memoizedValues]);

  const acquireVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        return index;
      }
    }
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (!(index in tiles)) {
        tiles[index] = tileId;
        return index;
      }
    }
    throw new Error('no tiles are available');
  };

  const releaseVideoIndex = (tileId: number): number => {
    for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
      if (tiles[index] === tileId) {
        delete tiles[index];
        return index;
      }
    }
    return -1;
  };

  useEffect(() => {
    // remove tile id when attendee leave --sanjay balai
    const tileIds: { [tileId: number]: string } = {};
    const realTimeVideoAttendees = new Set();
    const removeTileId = (tileId: number): void => {
      const removedAttendeeId = tileIds[tileId];
      delete tileIds[tileId];
      realTimeVideoAttendees.delete(removedAttendeeId);
      setVideoAttendees(new Set(realTimeVideoAttendees));
    };

    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState: VideoTileState): void => {
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          tileState.isContent ||
          !tileState.tileId
        ) {
          return;
        }
        const index = acquireVideoIndex(tileState.tileId);
        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          videoElements[index]
        );
        setVisibleIndices((previousVisibleIndices) => ({
          ...previousVisibleIndices,
          [index]: {
            boundAttendeeId: tileState.boundAttendeeId,
          },
        }));
        // code for audio/video enable or not start
        if (tileState.active) {
          tileIds[tileState.tileId] = tileState.boundAttendeeId;
          realTimeVideoAttendees.add(tileState.boundAttendeeId);
          setVideoAttendees(new Set(realTimeVideoAttendees));
        } else {
          removeTileId(tileState.tileId);
        }
      },
      videoTileWasRemoved: (tileId: number): void => {
        const index = releaseVideoIndex(tileId);
        setVisibleIndices((previousVisibleIndices) => ({
          ...previousVisibleIndices,
          [index]: null,
        }));
        removeTileId(tileId);
      },
    });
  }, []);

  return (
    <>
      <Box className={cx('Mui_roster_slider_parent_container')}>
        {attendeeIds.length === 0 && (
          <Box className={cx('Mui_roster_slider_local_video_container')}>
            <LocalVideo
              view={'grid'}
              activeSpeaker={roster[currentUser!]?.active}
            />
          </Box>
        )}

        <Box
          className={cx('Mui_roster_slider_main_container', {
            Mui_roster_slider_display_none: attendeeIds.length === 0,
            Mui_roster_slider_display_flex: attendeeIds.length !== 0,
          })}
        >
          <div id="tileView" className={cx('Mui_roster_slider_tileview')}>
            <div
              id="scrollview"
              className={cx('Mui_roster_slider_sliderview')}
              style={{
                width: `796px`,
                justifyContent: 'center',
              }}
            >
              <div
                className={'ClassRoom_meeting_timer'}
                id="meeting_timer"
                ref={timerElement}
              ></div>
              <div
                className={cx({
                  Mui_roster_slider_active_local_video_view:
                    roster[currentUser!]?.active && !isContentShareEnabled,
                  Mui_roster_slider_not_active_local_video_view:
                    !roster[currentUser!]?.active,
                })}
                style={
                  roster[currentUser!]?.active && !isContentShareEnabled
                    ? {
                        width: rightDrawerOpen ? 'calc(100% - 301px)' : '100%',
                      }
                    : {
                        width: '195px',
                        margin: '0px 2px',
                      }
                }
                onClick={() => {
                  if (!isContentShareEnabled) {
                    chime?.updateActiveSpeaker(currentUser as string);
                  }
                }}
              >
                {attendeeIds.length !== 0 && (
                  <LocalVideo
                    activeSpeaker={roster[currentUser!]?.active}
                    view={
                      roster[currentUser!]?.active && !isContentShareEnabled
                        ? 'grid'
                        : 'activeSpeaker'
                    }
                  />
                )}
              </div>
              {Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((key, index) => {
                const visibleIndex = visibleIndices[index];
                const attendeeId = visibleIndex
                  ? visibleIndex.boundAttendeeId
                  : null;

                const activeSpeaker = visibleIndex
                  ? roster[visibleIndex.boundAttendeeId]?.active
                  : false;
                return (
                  <div
                    key={key}
                    className={cx({
                      Mui_roster_slider_active_local_video_view:
                        activeSpeaker && !isContentShareEnabled,
                      Mui_roster_slider_not_active_local_video_view:
                        !activeSpeaker,
                    })}
                    style={
                      activeSpeaker && !isContentShareEnabled
                        ? {
                            width: rightDrawerOpen
                              ? 'calc(100% - 301px)'
                              : '100%',
                          }
                        : {
                            width: '195px',
                            display: visibleIndex ? 'block' : 'none',
                            margin: '0px 2px',
                          }
                    }
                    onClick={() => {
                      if (!isContentShareEnabled) {
                        chime?.updateActiveSpeaker(attendeeId as string);
                      }
                    }}
                  >
                    <RemoteVideo
                      key={key}
                      enabled={!!visibleIndex}
                      videoElementRef={useCallback(
                        (element: HTMLVideoElement | null) => {
                          if (element) {
                            videoElements[index] = element;
                          }
                        },
                        []
                      )}
                      attendeeId={attendeeId}
                      activeSpeaker={activeSpeaker}
                      view={
                        activeSpeaker && !isContentShareEnabled
                          ? 'grid'
                          : 'activeSpeaker'
                      }
                    />
                  </div>
                );
              })}
              {attendeeIds.map((key: string) => {
                let rosterAttendee: RosterAttendeeType = {};

                if (roster) {
                  rosterAttendee = roster[key];
                }
                // const activeSpeaker = attendeeIdFullScreen === key ? true : false;
                const activeSpeaker = roster[key]?.active
                  ? roster[key]?.active
                  : false;
                if (!videoAttendees.has(key)) {
                  return (
                    <div
                      className={cx({
                        Mui_roster_slider_active_local_video_view:
                          activeSpeaker && !isContentShareEnabled,
                        Mui_roster_slider_not_active_local_video_view:
                          !activeSpeaker,
                      })}
                      style={
                        activeSpeaker && !isContentShareEnabled
                          ? {
                              width: rightDrawerOpen
                                ? 'calc(100% - 301px)'
                                : '100%',
                            }
                          : {
                              width: '195px',
                              margin: '0px 2px',
                            }
                      }
                      key={key}
                      onClick={() => {
                        if (!isContentShareEnabled) {
                          chime?.updateActiveSpeaker(key as string);
                        }
                      }}
                    >
                      <RosterLayout
                        key={key}
                        attendeeId={key}
                        activeSpeaker={activeSpeaker}
                        name={rosterAttendee?.name || ''}
                        view={
                          activeSpeaker && !isContentShareEnabled
                            ? 'grid'
                            : 'activeSpeaker'
                        }
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}
