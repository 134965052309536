/* eslint-disable  */

import classNames from 'classnames/bind';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import ChimeSdkWrapper from '../chime/ChimeSdkWrapper';
import getChimeContext from '../context/getChimeContext';
import styles from './ChatInput.css';
import MessageTopic from '../enums/MessageTopic';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { Box, IconButton } from '@mui/material';
import getGlobalVarContext from '../context/getGlobalVarContext';

const cx = classNames.bind(styles);
let chatMessageText: string = '';

export default React.memo(function ChatInput() {
  const chime: ChimeSdkWrapper | null = useContext(getChimeContext());
  const { globalVar } = useContext(getGlobalVarContext());
  const { isChatOpen } = globalVar;
  const [inputText, setInputText] = useState<string>('');
  const activeChannel = MessageTopic.PublicChannel;
  const [openEmojiPicker, setOpenEmojiPicker] = useState<boolean>(false);
  const [loadEmojis, setLoadEmojis] = useState<boolean>(false);
  const intl = useIntl();

  function onClick(emojiData: EmojiClickData, _event: MouseEvent) {
    setInputText(chatMessageText + emojiData.emoji);
  }

  useEffect(() => {
    chatMessageText = inputText;
  }, [inputText]);

  useEffect(() => {
    if (isChatOpen) {
      setLoadEmojis(true);
    }
  }, [isChatOpen]);

  const emojiPickerVal = useMemo(() => {
    return (
      <EmojiPicker
        height={260}
        width={280}
        theme={Theme.DARK}
        onEmojiClick={onClick}
        autoFocusSearch={true}
      />
    );
  }, [openEmojiPicker]);

  return (
    <>
      <Box
        className={cx({
          Mui_chat_input_display_none: !openEmojiPicker,
          Mui_chat_input_display_block: openEmojiPicker,
        })}
      >
        {loadEmojis && emojiPickerVal}
      </Box>
      <Box className={cx('Mui_chat_input_parent_container')}>
        <div className={cx('chatInput')}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
            className={cx('form')}
          >
            <input
              className={cx('input')}
              value={inputText}
              onChange={(event) => {
                const value = event.target.value;
                if (value.length <= 1000) {
                  setInputText(value);
                } else {
                  setInputText(value.substring(0, 1000));
                }
              }}
              onKeyUp={(event) => {
                event.preventDefault();
                if (event.keyCode === 13) {
                  setOpenEmojiPicker(false);
                  const sendingMessage = inputText.trim();
                  const msgObject = {
                    sendingMessage,
                    channel: activeChannel,
                    targetId: activeChannel,
                  };
                  const attendeeId =
                    chime?.configuration?.credentials?.attendeeId;
                  if (sendingMessage !== '' && attendeeId) {
                    chime?.sendMessage(
                      MessageTopic.GroupChat,
                      JSON.stringify(msgObject)
                    );
                    setInputText('');
                  }
                }
              }}
              placeholder={intl.formatMessage({
                id: 'ChatInput.inputPlaceholder',
              })}
            />
          </form>
        </div>
        <Box className={cx('Mui_chat_input_child_container')}>
          <IconButton
            onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
            color="inherit"
            className={cx('Mui_chat_input_emoji_icon')}
          >
            <EmojiEmotionsOutlinedIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
});
